@import "./utility.scss";

//placeholder
.MuiOutlinedInput-inputMarginDense {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #373d4b !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #373d4b;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #373d4b;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #373d4b;
  }
}

//material ui overrite
.MuiTypography-subtitle1,
.MuiTypography-body1 {
  color: $color-black;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: $color-secondary !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: $color-secondary !important;
}

.ac-title {
  font-size: 48px;
  font-weight: $font-bold;
}
.ac-sub-title {
  font-size: 40px;
  font-weight: 300;
}
.ac-vertical-paddding {
  padding: 20px 0px 24px;
}
.label {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin: 0px !important;
}
.subtitle {
  color: rgba(55, 61, 75, 0.5) !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  font-family: $font;
}
.combined-shape-icon {
  border-radius: 1rem;
  width: 16px;
  height: 16px;
  color: #fff;
  font-size: 10px;
  background: #f3b238;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  @include flex-center-align-content();
  padding: 7px;
  margin-right: 10px;
}
button {
  font-family: $font !important;
}
.ac-user-profile-img {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  margin-left: 8px;
}
.ac-navbar-mobile-user-profile-img {
  height: 32px;
  width: 32px;
  border-radius: 32px;
}

.ac-fullscreen-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.3s;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiCircularProgress-colorPrimary {
    color: #1239ff;
  }
}
