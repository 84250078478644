@import url(../../../css/utility.scss);
// css for Reject Modal
.reject-modal {
  padding: 0px;
  .modal-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    // screen size more than 1200
    @media screen and (min-width: 1200px) {
      min-width: 500px;
    }
  }
  .modalTitle {
    color: #0b3548;
    font-size: 20px;
    font-weight: 500;
    // width:calc(100% - 10%),
  }
  .comment {
    font-weight: 500;
    color: #0b3548;
    margin: 0px;
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }
  .comment-box {
    border: 1px solid #7c7c7c;
    width: 100% !important;
    padding: 15px;
    &:focus-visible {
      // color: RED;
      border: 1px solid #7c7c7c !important;
      // background-color: red
    }
  }
  // css for modal buttons
  .btn-wrap {
    display: flex;
    justify-content: space-between;
    margin: 1px -9px;
    float: right;
  }
}
