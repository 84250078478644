@import "../../../css/utility.scss";
.bottom-div {
  position: fixed;
  border-top: 1px solid #b9b9b9;
  bottom: 0px;
  width: calc(100% - 25%);
  text-align: right;
  padding-right: 10px;
  height: 70px;
  button {
    padding: 5px 80px;
    background-color: $secondary-theme-color !important;
  }
}
.user-form {
  input {
    height: 10px !important;
    border-radius: 4px;
  }
  input:required:valid {
    border: 1px solid rgb(250, 247, 247) !important;
  }
}
