@import "../../css/utility.scss";
.day-card-box {
  background-color: #f5f5f5 !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  .dayCard-content{
    position: relative;
    .txt-day {
      font-size: 16px;
      margin: 0 10px 10px !important;
    }
    .dayCard-wrap{
      border-radius: 6px;
      margin:0 10px;
      background-color: #ffff;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .daycard-pdfIcon{
        position: relative;
        left:15px;
      }
      .daycardSelect{
       
          padding-left:30px ;
          margin-left:-23px;
          position: relative;
          box-shadow: none;
      }
      .MuiOutlinedInput-notchedOutline{
        border: 0!important;
      }
    }
  }
  .MuiOutlinedInput-input {
    padding: 9.5px 14px !important;
    color: white;
  }
  .MuiSelect-nativeInput {
    opacity: 1 !important;
    border: 0;
    left: 17%;
    width: 80%;
    height: 25px;
    top: 9px;
  }
}
