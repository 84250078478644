@import "../../../../css/utility.scss";
.date-time-div {
  background-color: black;
  width: 187px;
  color: white;
  height: 38px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin-left: 10px;
}
.date-time-div1 {
  background-color: #b9b9b9;
  width: 187px;
  color: white;
  height: 38px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin-left: 10px;
}
.auditHistory-wrap {
  border: 1px solid #b9b9b9;
  margin: 5px;
  border-radius: 6px;
  height: calc(100vh - 244px);
  overflow: scroll;
  table {
    tr {
      border-bottom: 1px solid #b9b9b9;
      outline: none !important;
      td {
        width: 150px;
        border-right: 1px solid #b9b9b9;
        padding: 6px 16px !important;
        &:first-child {
          font-weight: 700;
        }
      }
    }
  }
}
