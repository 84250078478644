@import "./../css/utility.scss";
.Admin-BatchInfo-wrap{
   background:#F8FBFF;
   height:calc(100vh - 130px); 
}
.table-row{
  border: 1px solid #b9b9b9;
  border-radius: 4px;
}
.Admin-BatchInfo {
  padding: 5px 20px;
  background: #f8fbff;
  border: none;
  .dashBoardTh {
    font-size: 14px;
    font-weight: bold;
    color: #202020;
    height: calc(100vh - 178px);
    th{
      background-color: #fff !important;
    }
    table {
      border-spacing: 0 6px !important;
    }
    table tr td {
      padding: 6.5px 5px !important;
      height: 45px !important;
      // text-align: center;
      text-align: left;
    }
  }
}
.pagination-wrap {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 7px;
 // background: #f8fbff;
}
