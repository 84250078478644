@import url(../../css/utility.scss);
.activityCard-wrapper{
  margin-top: 40px;
  .activity-container{
    .activity-grey-container{
      padding: 2px;
      background-color: grey;
    }
    .activitycard-innerBox{
      .activityCard-header{
        display: flex;
        flex-direction: row;
      }
      .activityCard-content{
        padding: 0;
      }
      .expanLess-wrap{
        padding: 0 13px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .activity-details{
        overflow: scroll;
        border: 1px solid grey;
        margin: 8px;
      }
    }
  }
}
.activity-container {
  width: 97%;
  margin: 10px;
  overflow: visible !important;
  box-shadow: none !important;
  display: flex;
  flex-direction: row;
  border: 1px solid #bdbdbd;
}
.css-1qbkelo-MuiCardHeader-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ul-item {
  list-style-type: none;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
.list-item {
  span {
    min-width: 200px;
    display: inline-block;
    padding: 5px;
    &:first-child {
      max-width: 250px;
      text-align: right;
    }
  }
}
