@import "../../../css/utility.scss";

.added-list {
  background-color: #ececec;
  margin: 5px;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  .added-list-info {
    word-break: break-all;
    .MuiTypography-root {
      font-size: 14px !important;
      line-height: 16px;
    }
    img {
      width: 15px;
    }
  }
}

.text-title {
  margin-left: "1px";
  font-size: "14px";
}
