// @import "../../css/utility.scss";

.css-1ynyhby {
  margin: 0;
}
.checkboxicon{
  margin: -4px 4px;
}

.parent-table,
.parent-table td,
.parent-table th {
  border-collapse: separate !important;
  border-spacing: 8px !important;
  border: 0px;
font-weight: 500;
}

.parent-table tr {
  outline: 1px solid #ececec !important;
  border-radius: 4px;
}

.parent-table {
  width: 99% !important;
  .parent-table-th {
    tr {
      outline: 1px solid #b9b9b9 !important;
      th{
      font-weight: 600 !important;
      padding: 12px !important;
      }
    }
  }
  .MuiTableCell-root{
    padding:8px !important;
  }
}

