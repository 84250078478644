@import "../../../css/utility.scss";

.bottom-btn {
  background-color: $secondary-theme-color !important;
  margin: "10px" !important;
  color: #ffffff;
  border-radius: 8px !important;
}

.bottom-btn-selected {
  background-color: rgb(255, 255, 255) !important;
  color: $secondary-theme-color !important;
  border: 2px solid $secondary-theme-color !important;
  margin: "10px";
  border-radius: 8px !important;
}
