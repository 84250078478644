@import "../../css/utility.scss";
.table-output {
  border: 0;
  margin: 0;
  border-collapse: separate !important;
  border-spacing: 8px !important;
  outline: 1px solid black;
  th {
    border: 0;
    border-collapse: separate !important;
    border-spacing: 8px;
    font-weight: 600;
    min-width: 90px;
    &.MuiTableCell-root{
      padding:12px !important;
    }
  }
  td {
    border: 0;
    border-collapse: separate;
    border-spacing: 8px !important;
    padding: 5px !important;
    padding-left: 18px !important;
    font-weight: 500;
  }
  thead {
    tr {
      outline: 1px solid #b9b9b9;
      text-transform: capitalize;
    }
  }
  tr {
    margin: 10px 0px;
    outline: 1px solid #ececec;
    border-radius: 4px;
  }
}
.action-list {
  .MuiPopover-paper {
    box-shadow: none !important;
  }
  .MuiMenu-list {
    background-color: #ececec;
    padding: 0;

    .action-item {
      color: #000;
      font-size: 12px !important;
      padding: 8px 14px;
      border-bottom: 1px solid #b9b9b9;
      svg {
        margin-right: 8px;
      }
      &:hover {
        color: #fff;
        background-color: $secondary-theme-color;
      }
    }
  }
}
.css-rqglhn-MuiTable-root {
  border-collapse: separate !important;
  border-spacing: 8px !important;
}
