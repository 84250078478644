.signatureOutputContainer {
  height: 150px;
  width: 500px;
  border: 1px solid #b9b9b9;
  background: #fff;
  border-radius: 10px;
  padding: 2px;
  text-align: center;
  position: relative;
  .css-1ps6pg7-MuiPaper-root {
    box-shadow: none !important;
  }
}
.signatureContainer {
  height: 150px;
  width: 500px;
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  margin-bottom: 18px;
  padding: 10px;
  position: relative;
}
.signatureCanvas {
  width: 494px;
  height: 150px;
  padding: 10px;
}
.esignImage {
  margin: 0 auto;
}
.esignUploadedText {
  position: absolute;
  text-align: right;
  right: 10px;
  bottom: 10px;
}
