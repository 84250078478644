@import url(../../../css/utility.scss);
// css for Reject Modal
.clone{
  display: flex;
  margin-top: 10px;
  margin-bottom: -5px;

}
.button-clone{
  background-color: #4096fc !important;;
  padding: 2px 10px !important;
  margin-right: -15px;
  color: #fff !important;
}
