@import "../../css/utility.scss";
.MapTableFooter {
  z-index:50;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 8px;
  text-align-last: end;
  @media screen and (min-width: 1600px) {
    padding: 29px 20px;
  }
  .MuiButton-startIcon{
    margin: 0!important;
  }
  .MapTbleFbuttons {
    display: flex;
    justify-content: end;
    .MuiButton-startIcon {
      margin: 0px !important;
      width: 15px;
    }
  }
  button {
    font-size: 16px;
    border: 2px solid $secondary-theme-color;
    color: $secondary-theme-color;
    text-transform: capitalize;
    padding: 6.79px 5px;
    height: 38px;
    line-height: 14px;
    margin: 0 8px !important;
    min-width: 112px;
    .startIcon{
      margin: 0 !important;;
    }
    &:last-child {
      margin-right: 0;
    }
    &.btn-contained {
      color: #fff;
      // padding: 6.79px 50.6px;
      background-color: $secondary-theme-color;
    }
    &.Mui-disabled {
      border: 2px solid gray;
    }
    &.btn-contained.Mui-disabled {
      color: #fff !important;
      background-color: gray !important;
    }
    @media screen and (min-width: 1600px) {
      font-size: 18px;
      border: 3px solid $secondary-theme-color;
      height: 52px;
    }
  }
  .page-no {
    background: #ececec;
    border-radius: 4px;
    padding: 0px 3px;
    height: 21px;
    color: black;
    font-weight: 600;
  }
  .StackPage {
    margin: 10px -1px;
  }
}
