@import "../../css/utility.scss";
.p-header {
  padding: 5.71px 25px;
  // @media screen and (min-width: 1600px) {
  //  padding: 15px 25px;
  // }
}

.project-title {
  color: $secondary-theme-color;
  font-weight: 700 !important;
  font-size: 16px !important;
  display: flex;
  // width:100%;
  // padding: 5px 25px;
  @media screen and (min-width: 1600px) {
    font-size: 20px !important;
    //  padding: 15px 25px;
  }
}
.administartor-project-title {
  font-weight: 700 !important;
  font-size: 16px !important;
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  min-height: 50px;
  width: 100%;
  padding-left: 23px;
  .backIcon {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #000;
  }
  @media screen and (min-width: 1600px) {
    padding: 15px 25px;
  }
}

.admin-box {
  border-top: 1px solid #b9b9b9;
  border-bottom: 1px solid #b9b9b9;
}
