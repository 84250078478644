.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: #ffff;
  z-index: 9999;
  width: 100%;
}
.back-button {
  display: flex;
  align-items: center;
  // z-index: 9999;
}
.filter-style {
  margin-right: 27px;
}
.details-container {
  // height: calc(100vh - 100px);
  // overflow-y: scroll;
  border-radius: 0;
  display: flex;
  flex-direction: column;
}

.history-div1 {
  font-size: 12px;
  margin-top: -10px;
}
