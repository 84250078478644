@import url(../../css/utility.scss);
.outerPDf-wrapper {
  background-color: #f5f5f5!important;
  border: 1px solid #ececec;
  border-radius: 10px!important;
  .Pdf-headerName {
    margin-bottom: -8px;
    .Pdf-close {
      color: #525863;
    }
  }
  .card-wrapper {
    padding: 5px 16px 0;
    .wrapper {
      .pdf-textfield {
        border-radius: 10px;
        border: none;
      }
      .pdfCard-icon {
        margin: -42px;
        margin-top: -26px;
      }
    }
  }
}
