@import "../../css/utility.scss";
.sidebar-btn-grp {
  position: absolute;
  width: 250px;
  height: 5px;
  left: 40px;
  top: 110px;
  background: #2776d2;
  border-radius: 10px;
}
.Sidebar-add {
  width: 330px;
  height: calc(100vh - 50px) !important;
  left: 0px;
  top: 70px;
  background-color: $secondary-theme-color;
  padding: 40px;

  .btn-sidebar,
  .btn-sidebar:hover {
    background-color: #2776d2;
    color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    justify-content: flex-start;
    padding: 8px 16px;
  }

  .btn-sidebar-active,
  .btn-sidebar-active:hover {
    background-color: #ffffff;
    // color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    justify-content: flex-start;
    padding: 8px 16px;
  }
  .btn-sidebar-selected {
    background-color: white;
    color: #2776d2;
    border-radius: 10px;
    margin-bottom: 10px;
    justify-content: flex-start;
    padding: 8px 16px;
  }
}

.sidebar-btn {
  margin-bottom: 1px;
}

.sidebar-link {
  text-decoration: none;
}
