@import "../../css/utility.scss";
.dashBoardTh {
  box-shadow: none;
  font-size: 14px;
  font-weight: 600;
  color: #202020;
  padding: 0 5px 5px;
  align-items: center;
  border: 0;
  height: calc(100vh - 130px);
  overflow-y: scroll;
  // text-align: center;
  text-align: left;
  table {
    color: "#2D2D2D";
    border-spacing: 0 10px !important;
    border: 0;
    border-collapse: separate !important;
    thead {
      th {
        background-color: #e8e7e7;
        font-weight: 700 !important;
        // text-align: center;
        text-align: left;
        padding: 4.5px 5px !important;
        &:first-child {
          width: 50px;
        }
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
    tr {
      td {
        // text-transform: capitalize;
        font-weight: 500;
        padding: 6px 5px !important;
        height: 58px !important;
        // text-align: center;
        text-align: left;
        &:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .MuiSvgIcon-root {
            order: 2 !important;
            padding-left: 2px !important;
            margin-left: 10px;
          }
        }
      }
      // th{
      //     border-bottom:0!important ;
      //     padding: 5px;
      // }
      background-color: #fff !important;
      height: 50px;
      &.hidden-row {
        padding: 5px;
        background: #f5f5f5 !important;
        align-items: center;
        text-align: center;
        width: 100% !important;
        border-bottom: 1px solid #b9b9b9;
        margin: 0 !important;
        text-transform: capitalize;
        &:first-child {
          margin-top: -5px !important;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
.row-wrap {
  text-align: center;
  text-transform: capitalize;
  .drugType {
    .MuiChip-label {
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 12px;
    }
  }
}
.action-list {
  .MuiPopover-pape {
    box-shadow: none !important;
  }
  .MuiMenu-list {
    background-color: #ececec;
    padding: 0;

    .action-item {
      color: #000;
      font-size: 12px !important;
      padding: 8px 14px;
      border-bottom: 1px solid #b9b9b9;
      svg {
        margin-right: 8px;
      }
      &:hover {
        color: #fff;
        background-color: $secondary-theme-color;
      }
    }
  }
}
.img{
  height: 21px;
  width: 17px;
}
.table-header-title:nth-child(7) {
  width: 150px;
}
@media screen and (min-width: 1600px) {
  .dashBoardTh {
    height: calc(100vh - 163px) !important;
  }
}
