@import "../../../css/utility.scss";

// .manage-output-files{
//     .output-files-wrap{
//         height: calc(100vh - 64px);
//         overflow: scroll;
//         display: flex;
//         flex-direction: column;
//     }
//     .output-files-table{
//         padding: 15px;
//         margin-top: 15px;
//     }
// }
.dp-main-container {
  padding: 4px 15px;
}

.manage-output-conatiner {
  display: flex;
  flex-direction: column !important;

  .manage-data-list {
    // margin-top: 16px;
    height: 61vh;
    padding: 4px 20px;
  }

  .input-wrap-container {
    align-items: center;
    padding: 14px 20px 0px !important;

    .search {
      position: "relative";
      border-radius: 1px;
      background-color: var(--bg-white);
      border: 1px solid #c5c0c0;
      border-radius: var(--border-radius6);
      display: flex;
      align-items: center;
      margin: 0 15px;
      width: "100%";
      padding: 4px;
    }

    .search-wrapper {
      padding: 0 2px;
      height: "100%";
      position: "absolute";
      pointer-events: "none";
      display: "flex";
      align-items: "center";
      justify-content: "center";
    }
  }

  .input-wrap-container-select {
    margin: 0 5px;
  }
}

.search-wrapper {
  padding: 0 2px;
  height: "100%";
  position: "absolute";
  pointer-events: "none";
  display: "flex";
  align-items: "center";
  justify-content: "center";

  .input-style {
    font-size: 14px !important;
  }

  .MuiInputBase-input {
    padding: 0px 0 5px !important;
  }
}

.DataPointDetails-container {
  .select-grid {
    margin: 0 3px;
  }

  // padding: 10px;
  display: flex;

  .search {
    .search-wrapper {
      .MuiSvgIcon-root {
        float: right;
        color: #b9b9b9;
      }
    }
  }
}

.demo-multiple-controls {
  width: 250px;
  margin-right: 14px !important;

  .multiple-controlsLabel {
    margin: -6px 0px 0px 13px !important;
  }
}

.multiplecontrolsLabel {
  margin-top: -7px !important;
}

.datapoint-btn-wrap {
  padding: 10px;
  display: flex;
  flex-direction: row-reverse !important;

  .download-btn {
    padding: 6.76px 30px;
    margin-right: 2px;
  }
}

.datapoint-map-wrap {
  padding: 4px 20px;
  height: 65vh;

  .datapoint-map-container {
    overflow-y: scroll;
    height: calc(100vh - 229px);
  }
}

.dataPointDetail1 {
  display: flex;
  justify-content: center;
  padding: 7px;
  // background-color:#F8FBFF;
}