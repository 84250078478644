@import "../../css/utility.scss";

td {
  height: 30px;
}
.audit-table-style {
  th {
    background-color: #ececec;
    min-width: 250px;
  }
  td,
  th {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
}
.audit-table-disable {
  background-color: #ececec;
  color: #b9b9b9;
}
.css-1ex1afd-MuiTableCell-root {
  border-bottom: 0 !important;
}
.css-dtizuz-MuiTableRow-root,
.css-1q1u3t4-MuiTableRow-root {
  outline: 1px solid #ececec !important;
}
