@import "../css/utility.scss";
.main-pageNotFound-container {
  height: 90%;
  align-items: center;
  .pageNotFound-data {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    .pageNotFound-card {
      flex: 1 0 auto;
      .card-info-wrap {
        margin-left: 8px;
      }
      .go-back-btn {
        padding-top: 20px;
        margin: 0 !important;
      }
    }
  }
  .pageNotFound-icon {
    img {
      margin-left: 11px;
    }
  }
}
