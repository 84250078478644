@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600");
@import url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");

html,
body,
#root {
  color: #000;
  font-size: 16px;
  font-family: "Montserrat", sans-serif !important;
  background-color: #fff;
  height: 100%;
  width: 100%;
  font-weight: 400;
  position: relative;
  line-height: 1.5;
}

// font variable
$font: "Montserrat";
$para-font: -apple-system, BlinkMacSystemFont, sans-serif;

// font weight
$font-bold: 600;
$font-semi-bold: 400;

// shaodw variable
$shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
$button-shadow: -1px 2px 2px rgba(0, 0, 0, 0);
$button-shadow-onHover: -1px 2px 2px #091624;

// color variables
$color-primary: #a03037;
$color-secondary: #1239ff;
$color-secondary-dark: #255385;
$color-white: #fff;
$color-black: #000;
$color-gray: #d0d0d0;
$color-error: #fd3458;
$color-light-gray: #f5f5f5;

$color-border: #dddddd;
$color-book-border: #dddbdb;
$color-button-border: #9d9d9d;
$color-book-outOfStock-backgorund: rgba(255, 255, 255, 0.7);

$colorSuccess: #48b06b !important;
$colorError: #fd3458 !important;
$colorWarning: #ffaa2a !important;
$colorTextBlue: #0067fb !important;
$colorTextWhite: #fff !important;
$colorTextBlack: #000 !important;

.Mui-disabled {
  cursor: not-allowed !important;
  color: gray !important;
  &::before {
    border-bottom-style: hidden !important;
  }
}
// flex box
@mixin felx() {
  display: flex;
  justify-content: center;
}
@mixin flex-align-start-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
}
@mixin flex-center-align-content {
  @include felx();
  align-items: center;
}
@mixin flex-row-align-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-flow: wrap;
}
@mixin flex-row-align-baseline {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
@mixin flex-column-center {
  flex-direction: column;
  @include flex-center-align-content();
}

// mixins
@mixin button {
  width: 200px;
  padding: 10px 30px;
  font-size: 15px;
  color: $color-white;
  background-color: $color-secondary;
  border: 1px solid $color-secondary;
  border-radius: 2px;
  outline: none;
  box-shadow: $button-shadow;
  transition: all 0.2s ease;

  &:hover {
    background-color: $color-secondary-dark;
    border: 1px solid $color-secondary-dark;
    box-shadow: $button-shadow-onHover;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0px);
    box-shadow: $button-shadow;
  }
}

// Text CSS
.success {
  color: #48b06b !important;
}
.error {
  color: #fd3458 !important;
}
.warning {
  color: #ffaa2a !important;
}
.text-blue {
  color: #0067fb !important;
}
.text-white {
  color: #fff !important;
}
.text-black {
  color: #000 !important;
}
// center classes
.h-center {
  display: flex;
  justify-content: center;
}

// theme color
$main-theme-color: #1fc8cc;
$secondary-theme-color: #4096fc;

.input-wrap {
  margin-bottom: 16px;
}

.MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 14px;
}

.checkboxicon {
  display: inline-block;
  height: 18px;
  width: 18px;
  border: 1px solid #b9b9b9;
  border-radius: 3px;
}
.MuiCard-root {
  box-shadow: none !important;
}
.MuiButton-root,
.MuiOutlinedInput-root {
  border-radius: 6px !important;
  box-shadow: none !important;
}
.MuiMenuItem-root {
  font-size: 14px !important;
}
.MuiTypography-root,
.MuiStepLabel-label {
  font-family: "Montserrat", sans-serif !important;
}
@media screen and (min-width: 1600px) {
  .MuiButton-root,
  .MuiOutlinedInput-root {
    border-radius: 10px !important;
  }
}

.w-100{
  width: 100%!important;
}
#alert-dialog-slide-description{
  overflow-wrap: break-word!important;
}