@import "../../../css/utility.scss";

.Sidebar {
  position: absolute;
  width: 330px;
  height: 710px;
  left: 0px;
  top: 70px;
  background-color: $secondary-theme-color;
}

.group-table,
.group-table td,
.group-table th {
  border-collapse: separate !important;
  border-spacing: 8px !important;
  border: 0px;
}

.group-table tr {
  outline: 1px solid #ececec !important;
  border-radius: 4px;
}

.group-table {
  width: 99% !important;
  .group-table-th {
    tr {
      outline: 1px solid #b9b9b9 !important;
    }
  }
}
