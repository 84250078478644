@import "../../../css/utility.scss";
.logInImg {
  height: 100vh;
  // background:linear-gradient(to right,#1FC8CC, #4096FC);
  //  background: #4096FC; //background for bbb logo
  background: #0037b2;
  background-size: cover;
}
.logInForm {
  background-color: $color-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // padding: 40px;
}
.logInImg-wrap {
  position: fixed;
  bottom: 5%;
  right: 5%;
  // top:5%;
  // left: 5%;
  max-width: 200px;
  img {
    // margin:60px 50px;
    width: 100%;
    height: auto;
  }
}
.loginFormWrapper {
  justify-content: center;
  align-items: center;
  height: 100vh;
}
