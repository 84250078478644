@import "../../../css/utility.scss";
.batchProcessing {
  padding: 40px;
  .blueLabel {
    color: $main-theme-color;
    font-weight: 700;
  }
  .input-wrap-title {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-around;
  }
  .input-wrap {
    margin-bottom: 1rem;
  }
  .batch-processing-form {
    label {
      font-size: 16px;
      font-weight: 600;
      color: #2d2d2d;
      margin-bottom: 10px;
      display: inline-block;
    }
    .MuiOutlinedInput-input {
      padding: 8px 14px !important;
    }
  }
  .Date-picker {
    // padding: 8px 14px !important;
    width: 100%;
    height: 40px !important;
    padding: 8px 14px !important;
    /* Base Color/Primery */
    background: #ffffff;
    //font-family: mon;
    font-family: Montserrat, sans-serif;
    /* Base Color/Lines */
    border: 1px solid #b9b9b9 !important;
    border-radius: 10px !important;
  }
}
