.gridBox {
  padding: "20px";
}

.tag-name-error {
  padding-left: 50px;
  color: #fd3458;
}

.pdf-card-container {
  padding-left: 49px;
    padding-right: 49px;
    height: calc(100vh - 300px);
    overflow-y: scroll;
}