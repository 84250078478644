@import "../css/utility.scss";
.css-1rq8huz-MuiGrid-root,
.css-3weykx-MuiGrid-root,
.MuiGrid-item {
  font-size: 14px !important;
  padding: 0px;
}
.tableHeader {
  background-color: #fff;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  padding: 8px;
}
.table-data {
  border: 1px solid #ececec !important;
  border-radius: 4px;
  margin: 5px 0 !important;
}
// .css-mhc70k-MuiGrid-root > .MuiGrid-item {
//   padding-top: 0 !important;
// }

.action-wrap {
  align-items: center;
  padding: 4px 20px;
  .react-datepicker-popper {
    z-index: 22;
  }
}
.search {
  position: "relative";
  border-radius: 1px;
  background-color: #ffffff;
  border: 1px solid #c5c0c0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin: 0 15px;
  width: "100%";
  padding: 4px;
}
.search-wrapper {
  padding: 0 2px;
  height: "100%";
  position: "absolute";
  pointer-events: "none";
  display: "flex";
  align-items: "center";
  justify-content: "center";
}
.hidden-row {
  padding: 5px;
  background: #f5f5f5;
  align-items: center;
  width: 100% !important;
  border-bottom: 1px solid #b9b9b9;
  margin: 0 !important;
  &:first-child {
    margin-top: -5px !important;
  }
  &:last-child {
    border-bottom: none;
  }
}
.text-center {
  text-align: center;
}
.input-drop-select {
  background-color: #f5f5f5;
  border: 1px solid #b9b9b9;
  border-radius: 6px;
  padding: 0;
  color: $secondary-theme-color;
  font-size: 14px !important;
  border-right: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 3.2px;
}
.break-word {
  word-break: break-all;
  font-weight: 500;
}
@media screen and (min-width: 1600px) {
  .search-container,
  .search {
    border-radius: 10px;
  }
}
