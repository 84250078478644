@import "../../../css/utility.scss";

.Sidebar {
    position: absolute;
    width: 330px;
    height: 710px;
    left: 0px;
    top: 70px;
    background-color: $secondary-theme-color;
}

.added-list {
    background-color: #ececec;
    margin: 5px;
    border-radius: 8px;
}

.scroller {
    overflow-y: scroll;
    height: 69vh !important;
    margin-top: 2vh !important;
    margin-bottom: 0 !important;
    scrollbar-color: rebeccapurple green;
    scrollbar-width: thin;
}

.nested-folder {
    margin-left: 47px;
}

.notselected {
    display: flex;
    cursor: pointer;
}

.selected {
    display: flex;
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 5px 10px;
    min-width: 200px;
}

.card-div {
    padding: 0.13px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-div-unselected {
    padding: 0.13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $secondary-theme-color;
}

.header-select {
    .MuiOutlinedInput-input {
        padding: 7.5px 14px;
    }

    .ProductTypeList-wrap {
        min-width: 200px;

        .ProductTypeList {
            margin-top: -9px;
        }
    }
}

.add-btn {
    background-color: $main-theme-color !important;
    padding: 6px 40px !important;
    margin: 0 15px !important;
    height: 38px;
}

.btn-continue {
    padding: 6px 52px !important;
    margin: 0 !important;
    margin-left: auto !important;
    background-color: $secondary-theme-color !important;
    color: #fff !important;

    &.Mui-disabled {
        background-color: #b9b9b9 !important;
        color: #fff !important;
    }
}

.list_directory-container {
    // padding: 27px;
    overflow-y: scroll;
    height: 68vh;
    margin: 0 !important;
    width: 100% !important;

    .card-wrap {
        padding: 18px;
    }

    .wrapperClass {
        display: flex;
        align-items: center;

        .selected {
            p {
                margin: 4px;
            }
        }

        .formlabel {
            margin: 0;
        }

        .m-4 {
            margin: 4px;
        }
    }

    // .main-card{
    //   width:250px;
    // }
}

.list-directory {
    position: relative;

    .header-select {
        height: calc(100vh - 64px);
    }

    .Add-batch-records {
        display: flex;
        padding: 10px 27px;
        border-bottom: 1px solid #b9b9b9;
    }

    .batch-product-type {
        width: 200px;
    }
}

.sub-folder {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 76px;
    min-width: 199px;
    max-width: 199px;
    background-color: #ececec;
    border-bottom: 1px solid #b9b9b9;
    padding: 0 10px;
    font-size: 12px;
    line-height: 14px;

    .formlabel {
        margin: 0 !important;
    }

    &:last-child {
        border-bottom: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    &:first-child {
        margin-top: -5px;
    }

    .folderNTiltle-wrap {
        display: flex;
        justify-content: center;
        cursor: pointer;

        p {
            padding: 0 8px;
        }
    }
}

.folder-icon {
    color: #ffb000;
}

.m-0 {
    margin: 0 !important;
}

.merge-pdf-header {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    padding: 0 22px;
    justify-content: space-around;
}