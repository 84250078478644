@import "../../css/utility.scss";
.AddRow-wrap {
  h6 {
    margin: 0;
    font-size: 14px;
    color: #202020;
    font-weight: 500;
  }
  .rightClick-icon {
    background: rgba(0, 0, 0, 0.7) !important;
    border-radius: 6px !important;
    color: #fff !important;
    margin-left: 6px !important;
    padding: 7.5px !important;
  }
  .addRow-inputField {
    display: flex;
    justify-content: center;
    width: 180px;
  }
  .MuiOutlinedInput-input {
    padding: 6.5px 21px !important;
  }
  .MuiOutlinedInput-root {
    padding-left: 0;
  }
  // .MuiOutlinedInput-input{
  //   text-align: center;
  //   padding: 0;
  // }
}

// Read more option css
.MuiSpeedDial-directionDown,
.MuiSpeedDial-directionRight {
  top: 5px !important;
  left: -15px !important;
}
.MuiSvgIcon-root {
  width: 21px !important;
  height: 21px !important;
}
.MuiSpeedDial-fab {
  width: 34px !important;
  height: 32px !important;
  background-color: #ececec !important;
  border-radius: 4px !important;
  color: #202020 !important;
  box-shadow: none !important;
}
.addRow-dataMap{
  padding-bottom: 5px!important;
}
