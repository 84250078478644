@import "../../css/utility.scss";
.action-bar {
  .MuiToolbar-root {
    min-height: 43px;
  }
  background-color: #f8fbff;
  box-shadow: none;
  .actionbar-bg {
    box-shadow: none;
    background-color: #f8fbff;
    position: static;
    .action-wrap {
      padding: 0;
      .react-datepicker-popper {
        z-index: 22;
      }
    }
  }
  @media screen and (min-width: 1600px) {
    padding: 6px 0;
  }
  .action-wrap {
    align-items: center;
  }
  .btn-search {
    background-color: $secondary-theme-color !important;
  }
  .btn-create-new-project {
    background-color: #4096fc !important;
    height: 38px;
    line-height: 15px;
    margin: 0;
    box-shadow: none;
    float: right;
  }
}
