@import "../../../css/utility.scss";
.ac-login-container-main {
  margin: 0 auto;
  max-width: 633px;
  // min-width: 450px;
  .CPV_Logo {
    padding: 0 40px 15px;
    text-align: center;
    margin: 0 auto 30px;
    border-bottom: 1px solid #b9b9b9;
    display: flex;
    align-items: end;
    img {
      width: 180px;
      margin: 0 auto;
      height: auto;
      margin: 0px 5px 0px 20px !important;
    }
  }
  .loginFormWrap {
    background: #fff;
    border-radius: 20px;
    padding: 7% 14%;
    box-shadow: 0 0 7px #6c6c6c;
    .css-1pejnv1-MuiInputBase-root-MuiOutlinedInput-root {
      background: #f5f5f5;
    }
    h5 {
      text-align: center;
      color: $main-theme-color;
      font-size: 22px;
      margin: 20px 0 10px;
    }
    input {
      padding: 10.95px 15px !important;
      width: 100%;
      font-size: 14px;
      background: #f5f5f5;
    }
    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
    .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
      border-radius: 6px !important;
      background: #f5f5f5;
    }
    .input-wrap {
      width: 100%;
      margin-bottom: 1rem;
      .input-wrap-icon {
        margin-bottom: 0;
        padding: 5px;
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
    label {
      font-size: 16px;
      margin-left: 15px;
      color: #828282;
    }
    .forgetPassword {
      // display: flex;
      // justify-content: space-between;
      text-align: center;
      font-weight: bold;
      margin-bottom: 1.5rem;
      button {
        font-size: 14px;
        padding: 0;
        margin-bottom: 0;
        height: 22px;
        &:hover {
          background-color: #fff;
        }
      }
    }
    .signin-disabled {
      &.MuiButtonBase-root {
        background-color: #b9b9b9 !important;
        color: white !important;
        margin-top: 40px;
      }
      button {
        font-weight: 600;
        font-size: 16px;
        padding: 0;
        margin-bottom: 0;
        height: 22px;
      }
    }
    .forgetPassword-disabled {
      button {
        font-weight: 600;
        font-size: 16px;
        padding: 0;
        margin-bottom: 0;
        height: 22px;
        background-color: #b9b9b9 !important;
      }
    }
    button {
      font-weight: 600;
      font-size: 16px;
      text-transform: none;
      margin-bottom: 10px;
      border-radius: 6px;
      padding: 7px 15px;
    }
    .signbtn {
      // background-color: $secondary-theme-color !important;
      background-color: #0037b2 !important;
      margin-top: 40px;
    }
    .logout {
      width: 100%;
      height: auto;
      border: 0;
      color: #202020;
      background: #fff;
      border: 2px solid #202020;
    }
    .error {
      label {
        color: $color-error;
        font-size: 14px;
        margin: 0 0 5px;
        display: inline-block;
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  .ac-login-container-main {
    width: 580px;
    .CPV_Logo {
      img {
        width: 220px;
      }
    }
    .loginFormWrap {
      h5 {
        font-size: 28px;
        margin: 20px 0 10px;
      }
      input {
        padding: 20.95px 15px !important;
        // border-radius: 10px;
      }
      .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
      .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 10px !important;
      }
      button {
        font-size: 18px;
        padding: 15.2px 0;
        height: 62px;
        border-radius: 10px;
      }
      .forgetPassword {
        font-size: 16px;
        height: 35px;
      }
      .signbtn,
      .logout {
        margin: 16px 0;
      }
    }
  }
}
