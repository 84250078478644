@import "../../../css/utility.scss";

.create-table {
  margin: 3px 0;
  border: 0 !important;
  thead {
    background: #fff;
    // background: #ececec !important;
    position: sticky;
    top: 0%;
    z-index: 9;
    tr {
      background: #ececec !important;
    }
  }
  th {
    border-bottom: 1px solid #b9b9b9 !important;
    border-right: 1px solid #b9b9b9 !important;
    border-top: 1px solid #b9b9b9 !important;

    &:first-child {
      border-left: 1px solid #b9b9b9 !important;
      position: sticky;
      background-color: #ececec;
      left: 0;
      z-index: 2;
      height: 42px !important;
      line-height: 1.5;
    }

    &.rejection-processor-label,
    &.rejection-validator-label,
    &.rejection-pre-processor-label {
      border-bottom: 0px !important;
      border-right: 0px !important;
      border-top: 0px !important;
      font-weight: 400;
      background-color: #fff;
    }
    .w-100 {
      width: 100%;
      line-height: 16px;
    }
  }
  tbody {
    tr {
      td {
        &:first-child {
          position: sticky;
          top: auto;
          left: 0;
          z-index: 1;
          background: #fff;
          border-bottom: 1px solid #b9b9b9 !important;
        }
      }
    }
  }
  td {
    border-bottom: 1px solid #b9b9b9 !important;
    border-right: 1px solid #b9b9b9 !important;
    padding: 0 0px !important;

    &:first-child {
      border-left: 1px solid #b9b9b9 !important;
    }

    // &:last-child{
    //   background: #fff;
    //   border-bottom: 0 !important;
    // }
    &.rejectCheckbox {
      border-bottom: 0 !important;
      border-right: 0 !important;
      background: #fff;
      // border-left: 0px !important;
      // justify-content: center;
      // display: flex;
      // width: 100%;
    }

    .MuiInput-root:before {
      border-bottom: 0 !important;
    }

    &.border-remove {
      border-bottom: none !important;
      border-right: none !important;
    }
  }
}

.CNCTable {
  border: 1px solid #b9b9b9;
  height: 98%;
  border-radius: 4px;
  padding: 0.3rem !important;

  .reset-btn {
    color: #fff;
    background-color: $secondary-theme-color;
    float: right;
    height: 34px;
    text-transform: capitalize;

    &:hover {
      background-color: $secondary-theme-color;
    }
  }

  .custom-row-columns {
    padding: 0 10px;
    max-width: 210px;
    margin-bottom: 3px;

    &:first-child {
      padding-left: 0;
    }

    .MuiInput-root {
      border: 1px solid #ececec;
      height: 34px;
      font-size: 14px;
      border-radius: 6px;
      padding: 0 5px;

      &:before {
        border: 0 !important;
      }
    }
  }

  .pagination {
    padding: 0.5rem;

    button {
      height: 30px;
      width: 30px;
      margin-right: 5px;
    }

    .gotoPage {
      width: 70px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #ececec;
      padding: 5px 10px;
      margin-right: 5px;
    }

    .showsNoOfPage {
      height: 32px;
      border-radius: 4px;
      border: 1px solid #ececec;
      padding: 5px 10px;
    }
  }

  .create-table-wrap {
    overflow: scroll;
    height: calc(100vh - 314px);

    .scrollable-element {
      scrollbar-width: none;
    }
  }
  .create-table-wrap1 {
    overflow: scroll;
    height: calc(100vh - 160px);

    .scrollable-element {
      scrollbar-width: none;
    }
  }
}

.reject-resould-btn {
  .readMore-btn {
    padding: 0;
    position: absolute;
    right: -3px;
    height: 20px;
    cursor: pointer;
    top: 0;
    border: 0;
    background-color: transparent;
    .MuiSvgIcon-root {
      width: 14px !important;
      height: 14px !important;
    }
  }

  position: relative;
  padding: 0;
  height: 100%;
  width: 100px;
  .MuiInput-root {
    padding: 4px 12px 5px 4px !important;
    height: 100% !important;
    font-size: 12px;
    line-height: 16px;
  }
  .Mui-disabled {
    cursor: pointer !important;
  }

  .disable_opacity {
    .css-66dh3a-MuiInputBase-input-MuiInput-input {
      cursor: pointer;
    }
  }

  button {
    position: absolute;
    padding: 0;
    right: -5px;
    // top: 2px;
    background: transparent;
    border: 0;
  }
}
.copy-label {
  display: inline-block;
  min-width: 101px;
}
.mx-5 {
  margin: 0 5px !important;
}
.context-menu {
  // .MuiMenu-root {
  position: absolute !important ;
  z-index: 25 !important;
  // }
}
// .MuiMenu-root {
//   position: absolute !important ;
//   z-index: 9999 !important;
// }
.rejection-processor-label,
.rejection-validator-label {
  .label {
    height: 37px;
  }
}

.checkbox-wrap {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 45px;
  input[type="checkbox"] {
    accent-color: rgb(255, 134, 134);
  }
  .MuiCheckbox-root {
    padding: 5px 9px !important;
    &:hover {
      background-color: transparent !important;
    }
  }
}
