@import "../../css/utility.scss";
.root {
  background-color: #cccccc;
}
.admin-box {
  background-color: #f5f5f5;
  display: flex;
}
.vertical {
  border-left: 2px solid #ececec;
  height: 2.34em;
  margin: 1px 13px;
}
.logo {
  height: 28px;
  width: 125px;
  cursor: pointer;
}
.toolBar {
  background-color: #ffff;
  box-shadow: none;
}

.admin-header {
  margin-left: auto;
  text-decoration: none;
  padding-right: 27px;
  // position: end;
  button {
    background-color: $secondary-theme-color !important;
    margin: 0 !important;
    height: 38px;
    min-width: 170px;
  }
}
.export-btn {
  background-color: $secondary-theme-color !important;
  padding: 6.76px 30px;
  margin: 0px;
  box-shadow: none;
  border-radius: 4px;
  .MuiButton-root {
    padding: 7px 39.9px;
  }
}

.header {
  .MuiToolbar-root {
    min-height: 50px;
  }
  .MuiTypography-subtitle1 {
    font-weight: 500;
    margin: 0 8px;
    color: #000000;
    padding: 1px 4px;
    font-size: 16px !important;
    line-height: 18px !important;

    @media screen and (min-width: 1600px) {
      font-size: 18px !important;
      margin: 18px 8px !important;
    }
  }
  .user-profile-icon {
    margin-left: "5px";
  }
}
