@import "../../../css/utility.scss";
.BasicDatePiker-wrap {
  align-items: center;
  .date-picker {
    border: transparent;
    padding: 7px 0 7px 0;
    width: 100%;
    &:focus-visible {
      outline: none;
    }
    .react-datepicker__input-container {
      input {
        text-align: center;
      }
    }
  }
  .admin-search {
    &.MuiGrid-item {
      padding-left: 15px !important;
    }
  }

  .btn-search {
    background-color: $secondary-theme-color !important;
    padding: 6.76px 30px;
    margin: 0px;
    box-shadow: none;
  }
  .status-search {
    min-width: 120px;
    background-color: #fff;
    margin: 0 15px;
  }

  .box {
    border: 1px solid #c5c0c0;
    display: flex;
    border-radius: 6px;
    padding: 4.7px 5.5px;
    background-color: #fff;
    align-items: center;
    .datePikerTo {
      color: black;
      padding: 0px 10px;
      font-size: 14px;
      font-weight: 500;
    }
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          text-align: center;
        }
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  .box {
    border-radius: 10px;
    padding: 5.5px;
  }
  .btn-search {
    padding: 8.76px 30px;
  }
}
