.sheet-names {
  input {
    width: 290px;
    background-color: #1fc8cc;
    border-radius: 10px;
    height: 40px;
    padding: 5px;
    margin: 5px;
    margin-bottom: 20px;
    border: 0;
  }
}

.doc-pdf {
  height: 65vh;
  overflow: scroll;
}
.pagination {
  display: inline-block;
}
.pagination button {
  color: black;
  float: left;
  padding: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
}
