@import "../../../css/utility.scss";
.new-project-wrap {
  justify-content: center;
  margin-top: 4%;
  .project-info {
    margin: 0 10px;
  }
  .subtitle2 {
    font-size: 14px;
    margin: 0 0 10px;
  }
  .MuiOutlinedInput-root {
    // border: 1px solid #b9b9b9;
    font-size: 14px;
    border-radius: 6px !important;
  }
  .MuiOutlinedInput-input {
    padding: 9.5px 25px !important;
  }
  @media screen and (min-width: 1600px) {
    .subtitle2 {
      font-size: 16px;
    }
    .MuiOutlinedInput-root {
      font-size: 16px;
      border-radius: 10px !important;
    }
    .MuiOutlinedInput-input {
      padding: 19.53px 25px !important;
    }
  }
}
