@import "../../../css/utility.scss";
.info-popup {
  .detail-popup {
    padding: 10px;
    .MuiTypography-root {
      font-size: 12px;
      line-height: 14px;
      word-break: break-word;
    }
  }
  .MuiButton-root {
    padding: 0 !important;
    font-size: 12px !important;
  }
}

.readMore-btn-cell-info {
  padding: 0;
  position: absolute;
  right: 0px !important;
  height: 20px;
  cursor: pointer;
  bottom: -2px;
  border: 0;
  background-color: transparent;
  .MuiSvgIcon-root {
    width: 14px !important;
    height: 14px !important;
  }
}
