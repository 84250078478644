@import "../../../css/utility.scss";
.mergeop-grid-container {
  .mergeop-grid1-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    .mergeop-text {
      margin: 16px;
      margin-bottom: 8px;
    }
    .mergeop-grid1-Box1 {
      margin: 69px -1px 0 -1px;
      overflow-y: scroll;
      height: 55vh;
      width: 100%;
      .mergeop-card1 {
        background: #ececec;
        border-radius: 3px;
        margin: 0 20px 10px 20px;
        padding: 9px;
        display: flex;
        .mergeop-lable {
          display: flex;
          align-items: center;
          color: #686060;
          font-weight: 500;
          width: 90%;
          word-break: break-all;
          .mergeop-input {
            height: 16px;
            width: 29px;
          }
        }
        .mergeop-tooltip {
          margin-left: auto;
        }
      }
    }
  }
  .mergeop-grid2-style {
    .mergeop-grid2-text-style {
      margin: 16px;
      margin-bottom: 8px;
      text-align: center;
    }
    .mergeop-grid2-Box1 {
      // margin-bottom: 22.4px;
      display: flex;
      justify-content: center;
      align-items: center;
      .mergeop-form-control {
        min-width: 170px;
        .mergeop-label {
          margin: -7px;
        }
      }
      .add-btn {
        width: 50px;
      }
    }
    .mergeop-grid2-Box2 {
      margin: 0px -1px 0 -1px;
      overflow-y: scroll;
      height: 55vh;
      text-align: -webkit-center;
      .mergeop-card2 {
        // width: fit-content;
        word-break: break-all;
        background: #ececec;
        border-radius: 3px;
        display: flex;
        align-items: center;
        // justify-content: space-around;
        margin-bottom: 11px;
        padding: 4px;
        margin: 10px 10px;
        .text-style {
          margin: 8px;
          text-align: center;
        }
      }
    }
  }
  .mergeop-grid3-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    .text-style {
      margin: 16px;
      margin-bottom: 8px;
      text-align: center;
    }
    .text-field {
      padding: 5px;
    }
    .mergeop-grid3-Box3 {
      width: 100%;
      margin: 13px -1px;
      overflow-y: scroll;
      height: 55vh;
      text-align: -webkit-center;
      .mergeop-list-style {
        width: 240px;
        background: #ececec;
        border-radius: 3px;
        max-width: 360;
        padding: 7px;
        margin: 8px;
        .mergeop-list-item {
          display: flex;
          flex-direction: row;
          width: 100%;
          word-break: break-all;
        }
        .mergeop-editable-text {
          padding: 5px;
        }
        .mergeop-collapse-list {
          background: #fff;
          width: 100%;
          word-break: break-all;
        }
      }
    }
  }
  .mergeop-submit-grid {
    border-top: 1px solid #b9b9bb;
    padding: 7.4px;
    .mergeop-submit-button {
      float: right;
      margin-right: 20px;
      // @include outline-blue-button;
    }
  }
}
